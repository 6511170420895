<template>
  <v-text-field
    :id="id"
    :ref="inputRef"
    :label="label"
    :loading="loading"
    :type="type"
    :disabled="disabled"
    :readonly="readonly"
    :prefix="prefix"
    :suffix="suffix"
    :hide-details="hideDetails"
    outlined
    :rules="rules"
    class="pt-0"
    :class="{ ...customClass, 'mt-3': !hideTopMargin }"
    v-model.trim="textinput"
    :append-outer-icon="appendOuterIcon"
    :placeholder="placeholder"
    v-on:click:append-outer="$emit('click:append-outer', true)"
    v-on:change="$emit('change', true)"
    v-on:keyup="$emit('keyup', true)"
    v-bind="vBindAttrs"
    v-on="vOn"
  ></v-text-field>
</template>
<script>
export default {
  name: "text-input",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    inputRef: {
      type: String,
      default: "textInput",
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    vBindAttrs: {
      type: Object,
      default: () => {
        return {};
      },
    },
    vOn: {
      type: Object,
      default: () => {
        return {};
      },
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideTopMargin: {
      type: Boolean,
      default: false,
    },
    appendOuterIcon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      textinput: null,
    };
  },
  watch: {
    value() {
      this.textinput = this.value;
    },
    textinput() {
      this.$emit("input", this.textinput);
    },
  },
  mounted() {
    this.textinput = this.value;
  },
};
</script>
